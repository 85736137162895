import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap';
import { OptionTemplateService } from '@app/features/system-settings/option-template/services/option-template.service';
import { ProposalTemplateService } from '@app/features/system-settings/proposal-template/services/proposal-template.service';
import {AuthService} from "@app/core";
declare var $: any;

@Component({
	selector: 'app-add-addon-modal',
	templateUrl: './add-addon-modal.component.html',
	styleUrls: ['./add-addon-modal.component.scss']
})
export class AddAddonModalComponent implements OnInit {
	public onClose: Subject<any>;
	title: string;
	lastPg: any;
	isOptionTemplate: any;
	additionalInformation: any;
	addOnTemplates: any[];
	addonType: string = 'blank';
	addonForm: FormGroup;
	editAddon: boolean = false;
	selectedTemplate: any;
	opportunityId: any;
    opportunityProposalId: any;
	action: string = 'save';
	select2: boolean = false;
	template: any;
	select2TemplateConfig: any;
	selectedAddon: any;
	optionsData: any[] = [];
	editorAddon: boolean = false;
    subscriptions: object = {};
    templatesLoading: boolean = true;
	showAddOnTemplate:boolean = false;
	additionalInfo:any;
    isViewMode:boolean = false;
    freemiumAccess:boolean = false;
    showAddNewTemplate:boolean = false;
    excludeOptions:any = [];
	constructor(private fb: FormBuilder,
		public bsModalRef: BsModalRef,
		public optionTemplateService: OptionTemplateService,
		public proposalTemplateService: ProposalTemplateService,
                private authService: AuthService,) { }

	ngOnInit() {
        let proposalEditor = this.authService.permitted(['AdvancedProposalEditor.readaccess']) === true;
        if(!proposalEditor){
            this.freemiumAccess = true
        } else {
            this.freemiumAccess = false
        }
		this.proposalTemplateService.templateName$.subscribe(template => {
			this.template = template;
		});
		this.onClose = new Subject();
		this.buildForm();
		if (this.editorAddon) {
			this.getAllTemplates();
		}
		if (this.selectedAddon && this.selectedAddon.hasOwnProperty('addOnName')) {
			this.addonForm.controls.optionName.setValue(this.selectedAddon.addOnName);
		}
        if (this.selectedAddon && this.selectedAddon.hasOwnProperty('customerOptionName')) {
            this.addonForm.controls.customerOptionName.setValue(this.selectedAddon.customerOptionName);
        }
        this.isViewMode = this.isViewMode;
	}

    initializeSelect2(optionTemplates) {
        let dataToSet = [];

        if(optionTemplates && optionTemplates.length) {
            optionTemplates.forEach((item: any) => {
                dataToSet.push({
                    id: parseInt(item.id),
                    text: item.optionName
                })
            });
        }

        this.select2TemplateConfig = {
            width: '100%',
            data: dataToSet
        }
        this.templatesLoading = false;
    }

    getAllTemplates() {
        this.additionalInfo['type'] = 'addon';
        this.subscriptions['optionsList'] = this.optionTemplateService.getAllOptionTemplates(this.additionalInfo).subscribe(data => {
            if (data) {
                this.showAddOnTemplate = true;
                for (let index = 0; index < data['excludeOptions'].length; index++) {
                    if(data['excludeOptions'][index]['addon'] == 1) {
                        this.excludeOptions.push(data['excludeOptions'][index]['id']);
                    }
                }
            }
        })
    }

	// Form buildForm
	buildForm() {
		this.addonForm = this.fb.group({
			'id': [''],
			'optionName': ['', Validators.required],
            'customerOptionName': ['']
		})
	}
	// submit option
	save(optionType) {
		let addInfo = this.additionalInformation;
		let opportunityIdVal = '';
        let opportunityProposalIdVal = '';
		let proposalTemplatesIdVal = '';
		let setupOptionIdVal = '';
		let selectedType = '';
		let pageNumber = '';
		let formValue = this.addonForm.value;
		if (this.editorAddon) {
			if (this.selectedTemplate == 'proposal') {
				proposalTemplatesIdVal = this.template.id;
			} else if (this.selectedTemplate == 'option') {
				setupOptionIdVal = this.template.id;
			} else {
				opportunityIdVal = this.opportunityId;
                opportunityProposalIdVal = this.opportunityProposalId;
			}
			selectedType = this.selectedTemplate;
			pageNumber = this.lastPg;
			this.action = 'saving';
			if (optionType == 'blank') {
				let option = {
					"opportunityId": opportunityIdVal,
                    'opportunityProposalId': opportunityProposalIdVal,
					"selectedTemplate": selectedType,
					"proposalTemplateId": proposalTemplatesIdVal,
					"setupOptionId": setupOptionIdVal,
					"setupOption": {
						"optionName": formValue.optionName,
                        "customerOptionName": formValue.customerOptionName,
					},
					"addOn": 'true',
					"lastPageNumber": pageNumber
				}
				this.saveAddon(option);
			}
			else {
				let option = {
					"opportunityId": opportunityIdVal,
                    'opportunityProposalId': opportunityProposalIdVal,
					"selectedTemplate": selectedType,
					"proposalTemplateId": proposalTemplatesIdVal,
					"setupOptionId": setupOptionIdVal,
					"optionTemplateId": formValue.optionName,
                    "customerOptionName": formValue.customerOptionName,
					"addOn": 'true',
					"lastPageNumber": pageNumber
				}
				this.saveAddon(option);
			}
		}
		else {
			if (!this.editAddon) {
				if (addInfo) {
					opportunityIdVal = addInfo.hasOwnProperty('opportunityId') && addInfo.opportunityId > 0 ? addInfo.opportunityId : '';
                    opportunityProposalIdVal = addInfo.hasOwnProperty('opportunityProposalId') && addInfo.opportunityProposalId > 0 ? addInfo.opportunityProposalId : '';
					proposalTemplatesIdVal = addInfo.hasOwnProperty('proposalTemplatesId') && addInfo.proposalTemplatesId > 0 ? addInfo.proposalTemplatesId : '';
					setupOptionIdVal = addInfo.setupOptionId;
					selectedType = addInfo.selectedTemplate;
					pageNumber = addInfo.pageNumber;
				}
				this.action = 'saving';
				let option: any;
				if (optionType == 'blank') {
					option = {
						"opportunityId": opportunityIdVal,
                        'opportunityProposalId': opportunityProposalIdVal,
						"selectedTemplate": selectedType,
						"proposalTemplateId": proposalTemplatesIdVal,
						"setupOptionId": setupOptionIdVal,
						"setupOption": {
							"optionName": formValue.optionName,
                            "customerOptionName": formValue.customerOptionName,
						},
						"addOn": 'true',
						"lastPageNumber": pageNumber
					}
					this.saveProposalOption(option)
				}
				else {
					option = {
						"opportunityId": opportunityIdVal,
                        'opportunityProposalId': opportunityProposalIdVal,
						"selectedTemplate": selectedType,
						"proposalTemplateId": proposalTemplatesIdVal,
						"setupOptionId": setupOptionIdVal,
						"optionTemplateId": formValue.optionName,
                        "customerOptionName": formValue.customerOptionName,
						"addOn": 'true',
						"lastPageNumber": pageNumber
					}
					this.saveProposalOption(option)
				}
			}
			else {
				let addOnName = this.addonForm.controls.optionName.value;
                const customerOptionName =  this.addonForm.controls.customerOptionName.value;
				let addOnDetails = {
					addOnName: addOnName,
					addOnId: this.selectedAddon.addOnId,
                    customerOptionName: customerOptionName
				};
				this.updateAddOn(addOnDetails);
			}
		}
	}
	updateAddOn(addOnDetails) {
		this.optionTemplateService.editAddOns(addOnDetails).subscribe(data => {
			this.action = 'save';
            if(!this.freemiumAccess){
                let msg = {
                    type: 'upgradeFreemium'
                };
                top.postMessage(msg, '*');
            }
			this.onClose.next(true);
			this.bsModalRef.hide();
		}, error => {
			console.log('err', error);
		})
	}
	//save Proposal Options
	saveAddon(option) {
		this.optionTemplateService.addProposalOptions(option).subscribe(data => {
            let contentPageId = '';
            if ( data ) {
                if ( data.hasOwnProperty('contentPageId')) {
                    contentPageId = data['contentPageId'];
                }
            }
			this.action = 'saving';
			this.onClose.next({ added: true, type: this.addonType, contentPageId: contentPageId });
			this.bsModalRef.hide();
		}, error => {
			console.log('err', error);
		})
	}

	//save Proposal Options
	saveProposalOption(option) {
		this.optionTemplateService.addOrLinkAddOns(option).subscribe(data => {
			this.proposalTemplateService.setSectionData(data);
			this.action = 'saving';
            if(!this.freemiumAccess){
                let msg = {
                    type: 'upgradeFreemium'
                };
                top.postMessage(msg, '*');
            }
			this.onClose.next({ added: true, type: this.addonType });
			this.bsModalRef.hide();
		}, error => {
			console.log('err', error);
		})
	}
	// Select option
	optionSelected(event) {
		this.addonForm.reset();
		this.addonType = event.target.value;
		if (this.select2 == false) {
			setTimeout(() => {
				$('select.addon-select2').select2();
			}, 500);
			this.select2 = true;
		}
	}

    templateSelected(event) {
        this.addonForm.controls['optionName'].setValue(event.templateId);
    }

    ngOnDestroy() {
        for (let subscription in this.subscriptions) {
            this.subscriptions[subscription].unsubscribe();
        }
    }

}
